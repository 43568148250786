import JSONBig from "json-bigint";

const useOfficeBridge = (module, onMessageReceived) => {
    if (typeof onMessageReceived === "function") {
        window.Office.context.ui.addHandlerAsync(
            window.Office.EventType.DialogParentMessageReceived,
            (arg) => {
                const messageFromParent = JSONBig.parse(arg.message);

                if (messageFromParent.module === module) {
                    onMessageReceived(
                        messageFromParent.action,
                        messageFromParent.data
                    );
                }
            }
        );
    }

    const sendMessageToOffice = (action, data) => {
        const envelop = {
            module: module,
            action: action,
            data: data,
        };

        const encodedEnvelop = JSONBig.stringify(envelop);

        window.Office.context.ui.messageParent(encodedEnvelop);
    };

    return {
        sendMessageToOffice,
    };
};

export default useOfficeBridge;
