<template>
    <div class="login-background">
        <a-row type="flex" align="middle" style="height: 100%">
            <a-col :span="14" :offset="5">
                <a-space
                    align="center"
                    direction="vertical"
                    :style="{ width: '100%' }"
                >
                    <a-card :bordered="false" style="width: 440px">
                        <a-space
                            :size="20"
                            direction="vertical"
                            :style="{ width: '100%' }"
                        >
                            <a-typography-title :level="3">
                                {{ config.frontendName }}
                            </a-typography-title>
                            <a-typography-title :level="5">
                                用户登录
                            </a-typography-title>
                            <a-space
                                :size="48"
                                direction="vertical"
                                :style="{ width: '100%' }"
                            >
                                <a-form
                                    ref="loginFormRef"
                                    :model="loginFormState"
                                    name="basic"
                                    :label-col="{ span: 0 }"
                                    :wrapper-col="{ span: 24 }"
                                    :rules="rules"
                                    autocomplete="off"
                                    validate-trigger="change"
                                    @finish="onFinish"
                                    @finish-failed="onFinishFailed"
                                >
                                    <a-form-item name="username">
                                        <a-input
                                            v-model:value="
                                                loginFormState.username
                                            "
                                            placeholder="请输入手机号"
                                            size="large"
                                        />
                                    </a-form-item>

                                    <a-form-item name="password">
                                        <a-input-password
                                            v-model:value="
                                                loginFormState.password
                                            "
                                            placeholder="请输入密码"
                                            size="large"
                                        />
                                    </a-form-item>

                                    <a-form-item>
                                        <a-button
                                            type="primary"
                                            html-type="submit"
                                            size="large"
                                            block
                                            :loading="loginFormState.submitting"
                                        >
                                            立即登录
                                        </a-button>
                                    </a-form-item>
                                    <a-typography-paragraph>
                                        <!-- eslint-disable-next-line -->
                                        <pre class="site-description">登录数字知识产权服务平台后，可使用插件登记文件，生成合法有效的证明文件，对外证明自己对原始文件的所有权。如果没有账号，可点击下方链接免费注册。</pre>
                                    </a-typography-paragraph>
                                </a-form>
                                <a-space
                                    align="center"
                                    direction="vertical"
                                    :style="{ width: '100%' }"
                                >
                                    <a-space>
                                        <a-typography-link @click="goToSignUp">
                                            注册账号
                                        </a-typography-link>
                                        <a-divider type="vertical" />
                                        <a-typography-link
                                            @click="goToResetPassword"
                                        >
                                            忘记密码
                                        </a-typography-link>
                                    </a-space>
                                </a-space>
                            </a-space>
                        </a-space>
                    </a-card>
                </a-space>
            </a-col>
        </a-row>
    </div>
</template>

<script>
export default {
    name: "UserLogin",
};
</script>

<script setup>
import { reactive, ref, watch } from "vue";
import UserAPI from "@/api/v2/user";
import { useStore } from "vuex";
import User from "@/models/user";
import { useRouter } from "vue-router";
import useOfficeBridge from "@/components/office-bridge";
import config from "@/config.json";

const store = useStore();
const router = useRouter();

const loginFormRef = ref();
const loginFormState = reactive({
    username: "",
    password: "",
    serverError: {
        field_name: "",
        message: "",
    },
    submittedBefore: false,
    submitting: false,
});

watch([() => loginFormState.username, () => loginFormState.password], () => {
    loginFormState.serverError.field_name = "";
    loginFormState.serverError.message = "";
});

let mobilePhoneCheck = async (_rule, value) => {
    if (value === "") {
        return Promise.reject("请输入手机号");
    } else if (!/^1[0-9]{10}$/.test(value)) {
        return Promise.reject("请输入正确的手机号");
    }

    return Promise.resolve();
};

let checkMobilePhoneOnSubmit = async (_rule, value) => {
    if (loginFormState.submittedBefore) {
        return Promise.resolve();
    }

    return mobilePhoneCheck(_rule, value);
};

let checkMobilePhoneOnChange = async (_rule, value) => {
    if (!loginFormState.submittedBefore) {
        return Promise.resolve();
    }

    return mobilePhoneCheck(_rule, value);
};

let passwordCheck = async (_rule, value) => {
    if (loginFormState.serverError.field_name === "password") {
        return Promise.reject(loginFormState.serverError.message);
    } else if (value === "") {
        return Promise.reject("请输入密码");
    }
};

let checkPasswordOnSubmit = async (_rule, value) => {
    if (loginFormState.submittedBefore) {
        return Promise.resolve();
    }

    return passwordCheck(_rule, value);
};

let checkPasswordOnChange = async (_rule, value) => {
    if (!loginFormState.submittedBefore) {
        return Promise.resolve();
    }

    return passwordCheck(_rule, value);
};

const rules = reactive({
    username: [
        {
            validator: checkMobilePhoneOnSubmit,
            trigger: "submit",
        },
        {
            validator: checkMobilePhoneOnChange,
            trigger: "change",
        },
    ],
    password: [
        {
            validator: checkPasswordOnSubmit,
            trigger: "submit",
        },
        {
            validator: checkPasswordOnChange,
            trigger: "change",
        },
    ],
});

const onFinishFailed = () => {
    loginFormState.submittedBefore = true;
    loginFormState.hasError = true;
};

const onFinish = (values) => {
    loginFormState.submittedBefore = true;
    loginFormState.submitting = true;
    UserAPI.authorize(values.username, values.password)
        .then(() => {
            UserAPI.getMyProfile().then((user) => {
                const currentUser = new User(user);
                store.commit("currentUser/setCurrentUser", currentUser);

                loginFormState.submitting = false;
                // Go back to previous route
                router.push(router.currentRoute.value.query.redirect || "/");
            });
        })
        .catch((err) => {
            loginFormState.submitting = false;

            if (err.type === UserAPI.ErrorType.Validation) {
                loginFormState.hasError = true;

                loginFormState.serverError.field_name = err.data.field_name;
                loginFormState.serverError.message = "用户名或密码错误";
                loginFormRef.value.validate();
            }
        });
};
const { sendMessageToOffice } = useOfficeBridge("User");
const goToSignUp = () => {
    sendMessageToOffice("openBrowser", config.frontendURL + "/user/signup");
};
const goToResetPassword = () => {
    sendMessageToOffice("openBrowser", config.frontendURL + "/reset-pass");
};
</script>

<style scoped lang="less">
.login-background {
    position: relative;
    height: 100%;
    background-image: url("./login-bg.jpg");
    background-size: auto 100%;
    background-position: center;
}
.site-description {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #666;
}
</style>
