import { createStore } from "vuex";
import persistedStorage from "@/store/modules/persisted-storage";
import currentUser from "@/store/modules/current-user";

const store = createStore({
    modules: {
        persistedStorage,
        currentUser,
    },
});

export default store;
