<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: "App",
};
</script>
<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { notification } from "ant-design-vue";
import v2 from "@/api/v2/v2";

const store = useStore();
store.commit("persistedStorage/initializePersistedStore");

const router = useRouter();

v2.onApiServerError(() => {
    notification.error({
        message: "网络错误",
        description: "请稍后重新尝试...",
    });
});

v2.onApiForbiddenError(() => {
    router.push({
        name: "login",
        query: { redirect: router.currentRoute.value.fullPath },
    });
});
</script>
<style lang="less">
#app {
    position: relative;
    height: 100%;
}
</style>
