import store from "@/store";
import jsonBig from "json-bigint";

const storeVersion = 1;

const state = () => ({
    storeVersion: 1,
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
    initializePersistedStore(state) {
        let persistedState = jsonBig.parse(
            localStorage.getItem("persisted-state")
        );

        // Check the version stored against current. If different, don't
        // load the cached version
        if (
            persistedState &&
            persistedState.persistedStorage.storeVersion === storeVersion
        ) {
            this.replaceState(Object.assign(state, persistedState));
        }

        store.subscribe((mutation, state) => {
            // Store the state object as a JSON string
            localStorage.setItem("persisted-state", jsonBig.stringify(state));
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
