import JsonModel from "@/models/json-model";

class Enterprise extends JsonModel {
    constructor(json) {
        super();

        this.id = null;
        this.name = "";
        this.license = "";
        this.integrals = 0;

        this.fromJson(json);
    }
}

export default Enterprise;
