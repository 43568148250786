<template>
    <a-form
        v-if="formStep === 1"
        :model="fileSubmitFormState"
        name="basic"
        :label-col="{ span: 0 }"
        :wrapper-col="{ span: 24 }"
        :rules="rules"
        autocomplete="off"
        validate-trigger="change"
        @finish="onFinish"
    >
        <a-space direction="vertical" size="large">
            <a-descriptions
                bordered
                :column="1"
                :label-style="{ width: '160px' }"
            >
                <a-descriptions-item label="文件标题">
                    <a-form-item name="name" has-feedback>
                        <a-input
                            v-model:value="fileSubmitFormState.name"
                            :bordered="false"
                            placeholder="请输入文件标题"
                            size="large"
                        />
                    </a-form-item>
                </a-descriptions-item>
                <a-descriptions-item
                    v-if="fileSubmitFormState.type === 'ts'"
                    label="选择项目"
                >
                    <a-form-item name="projectId" has-feedback>
                        <a-select
                            ref="select"
                            v-model:value="fileSubmitFormState.projectId"
                            :options="projectsReactive.projects"
                            :bordered="false"
                            :field-names="{
                                label: 'name',
                                value: 'value',
                            }"
                            size="large"
                            :style="{ width: '100%' }"
                        ></a-select>
                    </a-form-item>
                    <a-typography-text
                        v-if="
                            projectsReactive.loaded &&
                            projectsReactive.projects.length === 1
                        "
                        type="danger"
                    >
                        没有可选的商业秘密项目。请先
                        <a @click="openPlatformWeb">
                            登录{{ config.frontendName }}
                        </a>
                        创建项目，然后
                        <a @click="loadProjects">点击刷新</a>
                    </a-typography-text>
                </a-descriptions-item>
                <a-descriptions-item label="数字财产类型">
                    <a-radio-group
                        v-model:value="fileSubmitFormState.category"
                        name="cagetory"
                    >
                        <a-radio-button
                            v-for="category in categories"
                            :key="category.value"
                            :value="category.value"
                            :style="{ margin: '0 16px 16px 0' }"
                        >
                            {{ category.name }}
                        </a-radio-button>
                    </a-radio-group>
                </a-descriptions-item>
                <a-descriptions-item label="文件描述">
                    <a-form-item name="description">
                        <a-textarea
                            v-model:value="fileSubmitFormState.description"
                            show-count
                            placeholder="请输入文件描述（可选）"
                            :maxlength="300"
                            :bordered="false"
                            :allow-clear="true"
                            :auto-size="{ minRows: 3, maxRows: 10 }"
                        />
                    </a-form-item>
                </a-descriptions-item>
                <a-descriptions-item label="云端加密托管">
                    <a-space direction="vertical" size="middle">
                        <a-switch
                            v-if="fileSubmitFormState.type === 'ts'"
                            v-model:checked="fileSubmitFormState.uploadFile"
                            checked-children="是"
                            un-checked-children="否"
                        />
                        <a-typography-text
                            v-if="
                                !fileSubmitFormState.uploadFile ||
                                fileSubmitFormState.type === 'dip'
                            "
                            type="danger"
                        >
                            证据包将从本机内存中生成，由您自行下载到硬盘进行管理
                        </a-typography-text>
                        <a-typography-text
                            v-if="
                                fileSubmitFormState.type === 'ts' &&
                                !fileSubmitFormState.uploadFile
                            "
                            type="danger"
                        >
                            如果希望以更安全的方式管理证据包，请开启云端加密托管
                        </a-typography-text>
                        <a-typography-text
                            v-if="fileSubmitFormState.type === 'dip'"
                            type="danger"
                        >
                            如果希望以更安全的方式管理证据包，请使用商业秘密方式登记文件
                        </a-typography-text>
                        <a-typography-text
                            v-if="
                                fileSubmitFormState.uploadFile &&
                                fileSubmitFormState.type === 'ts'
                            "
                            type="success"
                        >
                            使用加密托管服务在云端备份证据包
                        </a-typography-text>
                    </a-space>
                </a-descriptions-item>
                <a-descriptions-item
                    label="文件数字指纹"
                    :content-style="{ background: '#fafafa' }"
                >
                    <a-typography-text
                        v-if="fileSubmitFormState.hash !== ''"
                        type="secondary"
                    >
                        0x{{ fileSubmitFormState.hash }}
                    </a-typography-text>
                    <a-typography-text
                        v-if="
                            fileSubmitFormState.hash === '' &&
                            hashProgress === -1
                        "
                        type="secondary"
                    >
                        正在准备文件...
                    </a-typography-text>
                    <a-typography-text
                        v-if="
                            fileSubmitFormState.hash === '' &&
                            hashProgress !== -1
                        "
                        type="secondary"
                    >
                        正在计算...{{ hashProgressText }}
                    </a-typography-text>
                </a-descriptions-item>
            </a-descriptions>
            <a-form-item :wrapper-col="{ span: 9, offset: 15 }">
                <a-space size="middle">
                    <a-typography-text
                        v-if="config.isSaaS && credits !== -1"
                        :type="price <= credits ? 'success' : 'danger'"
                        @click="gotoApiPage"
                    >
                        {{ price <= credits ? "消耗积分" : "积分不足" }}：{{
                            price
                        }}
                        / {{ credits }}
                    </a-typography-text>
                    <a-button
                        type="primary"
                        html-type="submit"
                        size="large"
                        :loading="formPreparing || formSubmitting"
                        :disabled="
                            (fileSubmitFormState.type === 'ts' &&
                                projectsReactive.loaded &&
                                projectsReactive.projects.length === 1) ||
                            (config.isSaaS && price > credits.value)
                        "
                    >
                        立即登记{{ uploadProgressText }}
                    </a-button>
                </a-space>
            </a-form-item>
        </a-space>
    </a-form>
    <a-row
        v-if="formStep === 2 || formStep === 3"
        type="flex"
        align="middle"
        style="height: 100%"
    >
        <a-col :span="14" :offset="5">
            <a-space
                align="center"
                direction="vertical"
                :style="{ width: '100%' }"
            >
                <a-card style="width: 660px">
                    <a-result v-if="formStep === 2" title="文件登记中">
                        <template #icon>
                            <lock-outlined />
                        </template>
                        <template #extra>
                            <a-steps
                                direction="vertical"
                                :current="waitingStep"
                                :percent="waitingPercent"
                            >
                                <a-step
                                    title="提交文件数字指纹"
                                    description="提交文件数字指纹和描述信息"
                                />
                                <a-step
                                    title="区块链登记"
                                    description="确权信息链上登记，等待区块链确认中..."
                                />
                                <a-step
                                    title="生成证据包"
                                    description="获取链上数据，在本地生成证据包"
                                />
                            </a-steps>
                        </template>
                    </a-result>
                    <a-result
                        v-if="formStep === 3"
                        status="success"
                        title="登记成功"
                        :sub-title="successMessage"
                    >
                        <template #extra>
                            <a-button
                                v-if="
                                    fileSubmitFormState.type !== 'ts' ||
                                    !fileSubmitFormState.uploadFile
                                "
                                key="console"
                                type="primary"
                                :loading="formSubmitting"
                                :disabled="evidenceDownloaded"
                                @click="downloadEvidencePackage"
                            >
                                下载证据包（{{ autoDownloadCountdown }}
                                秒后自动开始）
                            </a-button>
                            <a-button
                                v-if="
                                    fileSubmitFormState.type === 'ts' &&
                                    fileSubmitFormState.uploadFile
                                "
                                key="console"
                                type="primary"
                                :loading="formSubmitting"
                                @click="closeDialog"
                            >
                                完成
                            </a-button>
                        </template>

                        <div
                            v-if="
                                fileSubmitFormState.type !== 'ts' ||
                                !fileSubmitFormState.uploadFile
                            "
                            class="desc"
                        >
                            <p style="font-size: 16px">
                                <strong>原始文件保持私密</strong>
                            </p>
                            <p>
                                <check-circle-outlined
                                    :style="{ color: 'green' }"
                                />
                                原始文件从未离开您的计算机，数据保持安全私密。
                            </p>
                            <p>
                                <check-circle-outlined
                                    :style="{ color: 'green' }"
                                />
                                证据包在您的电脑的内存中生成，其中包括登记确权时使用的原始文件。
                            </p>
                            <p>
                                <info-circle-outlined
                                    :style="{ color: 'orange' }"
                                />
                                如果原始文件丢失或者发生变化，会导致证据链条不完整，从而使得确权记录无法使用。请您务必将证据包从内存中下载到本地硬盘，防止其丢失。
                            </p>
                        </div>

                        <div
                            v-if="
                                fileSubmitFormState.type === 'ts' &&
                                fileSubmitFormState.uploadFile
                            "
                            class="desc"
                        >
                            <p style="font-size: 16px">
                                <strong>加密托管服务确保原始文件安全</strong>
                            </p>
                            <p>
                                <check-circle-outlined
                                    :style="{ color: 'green' }"
                                />
                                原始文件通过加密托管服务进行云端备份，不会丢失。
                            </p>
                            <p>
                                <check-circle-outlined
                                    :style="{ color: 'green' }"
                                />
                                您可随时登录数字知识产权保护平台查看登记信息并获取证据包
                                <a @click="openPlatformWeb">立即查看 &gt;</a>
                            </p>
                        </div>
                    </a-result>
                </a-card>
            </a-space>
        </a-col>
    </a-row>
</template>

<script>
import { LockOutlined } from "@ant-design/icons-vue";
export default {
    name: "FileSubmit",
    components: [LockOutlined],
    computed: {
        credits() {
            return this.$store.state.currentUser.currentUser.enterprise
                .integrals;
        },
    },
};
</script>

<script setup>
import { onMounted, reactive, computed, ref, defineEmits } from "vue";
import useOfficeBridge from "@/components/office-bridge";
import ProjectsAPI from "@/api/v2/projects";
import DipFile from "@/models/dip-file";
import { notification } from "ant-design-vue";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";
import config from "@/config.json";
import { useRouter } from "vue-router";

const router = useRouter();

const fileSubmitFormState = reactive({
    type: router.currentRoute.value.query.type === "dip" ? "dip" : "ts",
    id: 0,
    name: "",
    hash: "",
    category: "a",
    description: "",
    projectId: 0,
    uploadFile: true,
});

const formPreparing = computed(() => {
    return (
        fileSubmitFormState.hash === "" ||
        (fileSubmitFormState.type === "ts" && !projectsReactive.loaded)
    );
});

const formSubmitting = ref(false);

const formStep = ref(1);

let checkProject = async (_rule, value) => {
    if (value === 0) {
        return Promise.reject("请选择文件的登记位置");
    }
    return Promise.resolve();
};

const rules = {
    name: [
        {
            required: true,
            message: "请输入文件标题",
        },
    ],
    projectId: [
        {
            validator: checkProject,
        },
    ],
};

const onFinish = () => {
    formSubmitting.value = true;
    sendMessageToOffice("submitFile", fileSubmitFormState);
};

let projectsReactive = reactive({
    projects: [
        {
            name: "-----请选择文件的登记位置-----",
            value: 0,
        },
    ],
    loaded: false,
});

const loadProjects = () => {
    ProjectsAPI.getProjects().then((loadedProjects) => {
        projectsReactive.loaded = true;

        loadedProjects.forEach((project) => {
            project.value = project.id.toString();
        });

        if (loadedProjects.length > 0) {
            projectsReactive.projects =
                projectsReactive.projects.concat(loadedProjects);
        }
    });
};

if (fileSubmitFormState.type === "ts") {
    loadProjects();
}

const price =
    fileSubmitFormState.type === "dip" ? config.price.dip : config.price.ts;

const categories = DipFile.categories;

const autoDownloadCountdown = ref(0);
let autoDownloadCountdownRef = null;

const startAutoDownloadCountdown = () => {
    autoDownloadCountdown.value = 10;

    autoDownloadCountdownRef = setInterval(() => {
        if (autoDownloadCountdown.value <= 0) {
            downloadEvidencePackage();
        } else {
            autoDownloadCountdown.value = autoDownloadCountdown.value - 1;
        }
    }, 1000);
};

const stopAutoDownloadCountdown = () => {
    if (autoDownloadCountdownRef !== null) {
        autoDownloadCountdown.value = 0;
        clearInterval(autoDownloadCountdownRef);
        autoDownloadCountdownRef = null;
    }
};

let waitingPercent = ref(0);
let waitingPercentRef = null;
let waitingStep = ref(1);
const startWaitingCountdown = () => {
    if (waitingPercentRef !== null) {
        clearInterval(waitingPercentRef);
    }

    waitingPercent.value = 0;

    waitingPercentRef = setInterval(() => {
        // Simulate a progress bar
        if (waitingStep.value === 1) {
            if (waitingPercent.value < 100) {
                waitingPercent.value = waitingPercent.value + 5;
            } else {
                waitingStep.value = 2;
                waitingPercent.value = 0;
            }
        } else {
            if (waitingPercent.value < 50) {
                waitingPercent.value = waitingPercent.value + 5;
            } else if (waitingPercent.value < 85) {
                waitingPercent.value = waitingPercent.value + 2;
            } else if (waitingPercent.value < 95) {
                waitingPercent.value = waitingPercent.value + 1;
            } else {
                clearInterval(waitingPercentRef);
                waitingPercentRef = null;
            }
        }
    }, 1000);
};

const { sendMessageToOffice } = useOfficeBridge("File", (action, data) => {
    if (action === "getFileMeta") {
        Object.assign(fileSubmitFormState, data);
    } else if (action === "submitFile") {
        formSubmitting.value = false;

        if (data.status === "done") {
            formStep.value = 3;
            if (
                fileSubmitFormState.type !== "ts" ||
                !fileSubmitFormState.uploadFile
            ) {
                startAutoDownloadCountdown();
            }
        } else if (data.status === "waitingForConfirmation") {
            formStep.value = 2;
            startWaitingCountdown();
        } else {
            console.log("submit file error", data);
            notification.error({ message: "网络错误，请稍后重试..." });
            uploadProgress.value = 0;
        }
    } else if (action === "saveEvidencePackage") {
        formSubmitting.value = false;

        if (data.status === "success") {
            const iframe = document.createElement("iframe");
            iframe.hidden = true;
            iframe.src = data.message;
            document.body.appendChild(iframe);
        } else {
            console.log("save evidence package error", data);
            notification.error({ message: data.message });
        }
    } else if (action === "uploadFileProgress") {
        uploadProgress.value = data;
    } else if (action === "updateHashProgress") {
        hashProgress.value = data;
    }
});

let evidenceDownloaded = ref(false);

const downloadEvidencePackage = () => {
    stopAutoDownloadCountdown();

    evidenceDownloaded.value = true;

    sendMessageToOffice("saveEvidencePackage", fileSubmitFormState);
};

const successMessage = computed(() => {
    if (fileSubmitFormState.type === "ts" && fileSubmitFormState.uploadFile) {
        return "原始文件已通过加密托管服务进行备份，可登录数字知识产权保护平台进行管理";
    } else {
        return "插件在计算机内存中生成了证据包，其中包含确权登记时使用的原始文件。请立即将证据包从内存中下载到本地硬盘，防止其丢失或被修改";
    }
});

let uploadProgress = ref(0);
const uploadProgressText = computed(() => {
    if (uploadProgress.value === 0) {
        return "";
    } else {
        return " (" + uploadProgress.value + "%)";
    }
});

let hashProgress = ref(-1);
const hashProgressText = computed(() => {
    if (hashProgress.value === 100) {
        return "";
    } else {
        return hashProgress.value + "%";
    }
});

const openPlatformWeb = () => {
    sendMessageToOffice("openBrowser", config.frontendURL);
};

const closeDialog = () => {
    sendMessageToOffice("closeDialog");
};

const gotoApiPage = () => {
    window.location = config.apiBaseURL;
};

onMounted(() => {
    sendMessageToOffice("getFileMeta");
});

const emit = defineEmits(["pageTitleChanged"]);

if (fileSubmitFormState.type === "ts") {
    emit("pageTitleChanged", "商业秘密登记");
} else {
    emit("pageTitleChanged", "数字财产登记");
}
</script>

<style scoped></style>
