import JsonModel from "@/models/json-model";
import Enterprise from "@/models/enterprise";

class User extends JsonModel {
    constructor(json) {
        super();

        this.id = null;
        this.name = "";
        this.enterprise = new Enterprise({});

        this.fromJson(json);
    }
}

export default User;
