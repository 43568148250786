import v2 from "@/api/v2/v2";
import BaseApi from "@/api/base-api";

class UserAPI extends BaseApi {
    authorize(username, password) {
        // The access token is stored in cookies automatically
        return v2.post("/users", {
            phone_number: username,
            password: password,
        });
    }

    getMyProfile() {
        return v2.get("/users/me");
    }

    getMyEnterprise() {
        return v2.get("/users/me/enterprise");
    }

    signOut() {
        return v2.del("/users/me");
    }
}

const userAPI = new UserAPI();

export default userAPI;
