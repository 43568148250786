if (window.browserUnsupported()) {
    throw new Error("Unsupported browser");
}

// Office.js replaces history.pushState & replaceState since it is not supported in some of its apps
// So we need to delete them before import vue-router to make vue-router work.
delete window.history.pushState; // workaround to make sure vue router doesn't try using pushState
delete window.history.replaceState; // workaround to make sure vue router doesn't try using replaceState

import { createRouter, createWebHistory } from "vue-router";
import { createApp } from "vue";

import routes from "./routes";
import App from "./App.vue";
import AntdGlobalComponents from "./antd-global-components";
import store from "@/store";
import config from "@/config.json";

window.Office.onReady(() => {
    // Create Vue app
    const app = createApp(App);

    // Some globally used Ant Design components
    AntdGlobalComponents.forEach((component) => {
        app.use(component);
    });

    // Create Vuex store
    app.use(store);

    // Create Vue router
    const router = createRouter({
        history: createWebHistory(),
        routes: routes,
    });

    // Router guard for unauthorized access
    router.beforeEach(async (to) => {
        if (to.name !== "login" && to.name !== "index" && to.name !== "about") {
            const userAuthenticated =
                store.getters["currentUser/isUserAuthenticated"];

            if (!userAuthenticated) {
                return { name: "login", query: { redirect: to.fullPath } };
            }
        }
    });

    router.afterEach((to) => {
        if (to.meta.title) {
            document.title = to.meta.title + " | " + config.frontendName;
        } else {
            document.title = config.frontendName;
        }
    });

    app.use(router);

    app.mount("#app");
});
