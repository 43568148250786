import {
    Menu,
    Dropdown,
    Layout,
    Typography,
    Row,
    Col,
    Space,
    Card,
    Tabs,
    Form,
    Input,
    Button,
    Descriptions,
    Radio,
    Select,
    Switch,
    Result,
    Steps,
    Divider,
    Avatar,
} from "ant-design-vue";

export default [
    Menu,
    Dropdown,
    Layout,
    Typography,
    Row,
    Col,
    Space,
    Card,
    Tabs,
    Form,
    Input,
    Button,
    Descriptions,
    Radio,
    Select,
    Switch,
    Result,
    Steps,
    Divider,
    Avatar,
];
