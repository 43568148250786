<template>
    <a-row type="flex" align="middle" style="height: 100%">
        <a-col :span="22" :offset="1">
            <a-space
                direction="vertical"
                align="center"
                :style="{
                    width: '100%',
                }"
            >
                <img src="@/assets/logo.png" alt="数字知识产权保护插件" />
                <a-typography-title :level="4">
                    数字知识产权保护插件
                </a-typography-title>
                <a-typography-title :level="4">
                    v{{ appVersion }}
                </a-typography-title>
                <a-typography-link @click="openPlatform">
                    https://ip.hetutec.com
                </a-typography-link>
                <a-typography-text>&copy;Hetutec 2016-2022.</a-typography-text>
                <a-typography-text>All rights reserved.</a-typography-text>
            </a-space>
        </a-col>
    </a-row>
</template>

<script>
export default {
    name: "IndexPage",
};
</script>

<script setup>
import pkg from "../../package";
const appVersion = pkg.version;
const openPlatform = () => {
    window.Office.context.ui.openBrowserWindow("https://ip.hetutec.com");
};
</script>
