import UserLogin from "@/components/login/user-login.vue";
import IndexPage from "@/components/index-page.vue";
import FileSubmit from "@/components/file/file-submit";
import UserDashboard from "@/components/user-dashboard";

export default [
    {
        path: "/",
        name: "index",
        component: IndexPage,
        meta: {
            title: "",
        },
    },
    {
        path: "/login",
        name: "login",
        component: UserLogin,
        meta: {
            title: "登录",
        },
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: UserDashboard,
        children: [
            {
                path: "file",
                name: "file",
                component: FileSubmit,
                meta: {
                    title: "产权登记",
                },
            },
        ],
    },
    {
        path: "/about",
        name: "about",
        component: IndexPage,
        meta: {
            title: "",
        },
    },
    {
        path: "/:pathMatch(.*)*",
        component: IndexPage,
        meta: {
            title: "",
        },
    },
];
