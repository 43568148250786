import UserAPI from "@/api/v2/user";
import Enterprise from "@/models/enterprise";

const state = () => ({
    currentUser: null,
});

// getters
const getters = {
    isUserAuthenticated(state) {
        return state.currentUser !== null;
    },
};

// actions
const actions = {
    updateEnterprise({ commit, state }) {
        UserAPI.getMyEnterprise().then((enterpriseJson) => {
            const enterprise = new Enterprise(enterpriseJson);
            const user = state.currentUser;
            user.enterprise = enterprise;
            commit("setCurrentUser", user);
        });
    },
    async signOut({ commit }) {
        await UserAPI.signOut();
        commit("setCurrentUser", null);
    },
};

// mutations
const mutations = {
    setCurrentUser(state, user) {
        state.currentUser = user;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
