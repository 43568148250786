<template>
    <a-layout>
        <a-layout-header style="background: #ffffff">
            <a-row justify="space-between">
                <a-col :span="12">
                    <a-typography-title
                        :level="3"
                        style="margin-top: 14px"
                        :ellipsis="true"
                        :content="pageTitle"
                    ></a-typography-title>
                </a-col>
                <a-col :span="8">
                    <a-dropdown placement="bottomRight">
                        <a class="ant-dropdown-link" @click.prevent>
                            <a-row justify="space-between">
                                <a-col :span="20" style="line-height: normal">
                                    <div
                                        style="
                                            text-align: right;
                                            margin-top: 14px;
                                        "
                                    >
                                        <a-typography-text>
                                            {{ user.name }}
                                        </a-typography-text>
                                    </div>
                                    <div style="text-align: right">
                                        <a-typography-text
                                            type="secondary"
                                            :ellipsis="true"
                                            :content="user.enterprise.name"
                                        ></a-typography-text>
                                    </div>
                                </a-col>
                                <a-col :span="3">
                                    <a-avatar style="background-color: #5b85eb">
                                        {{ avatarLetter }}
                                    </a-avatar>
                                </a-col>
                            </a-row>
                        </a>
                        <template #overlay>
                            <a-menu>
                                <a-menu-item @click="goToProfile">
                                    <UserOutlined />
                                    个人设置
                                </a-menu-item>
                                <a-menu-item @click="goToEnterprise">
                                    <HomeOutlined />
                                    企业中心
                                </a-menu-item>
                                <a-menu-divider />
                                <a-menu-item @click="signOut">
                                    <LogoutOutlined />
                                    退出
                                </a-menu-item>
                            </a-menu>
                        </template>
                    </a-dropdown>
                </a-col>
            </a-row>
        </a-layout-header>
        <a-layout-content style="background: #ffffff">
            <router-view @page-title-changed="pageTitleChanged"></router-view>
        </a-layout-content>
    </a-layout>
</template>

<script>
import {
    UserOutlined,
    LogoutOutlined,
    HomeOutlined,
} from "@ant-design/icons-vue";

export default {
    name: "UserDashboard",
    components: {
        UserOutlined,
        LogoutOutlined,
        HomeOutlined,
    },
    computed: {
        avatarLetter() {
            if (this.$store.state.currentUser.currentUser)
                return this.$store.state.currentUser.currentUser.name.substr(
                    0,
                    1
                );
            return "";
        },
    },
};
</script>
<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import config from "@/config.json";
import useOfficeBridge from "@/components/office-bridge";
import { Modal } from "ant-design-vue";
import { createVNode } from "vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

const store = useStore();
const router = useRouter();

store.dispatch("currentUser/updateEnterprise");

const user = store.state.currentUser.currentUser;
const { sendMessageToOffice } = useOfficeBridge("User");

const goToProfile = () => {
    sendMessageToOffice(
        "openBrowser",
        config.frontendURL + "/dashboard/setting"
    );
};

const goToEnterprise = () => {
    sendMessageToOffice(
        "openBrowser",
        config.frontendURL + "/dashboard/company"
    );
};

const signOut = () => {
    Modal.confirm({
        content: "确认要退出当前账号吗？",
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
            store.dispatch("currentUser/signOut").then(() => {
                router.push({
                    name: "login",
                    query: { redirect: "/dashboard/file?type=dip" },
                });
            });
        },
    });
};

let pageTitle = ref(router.currentRoute.value.meta.title);
const pageTitleChanged = (title) => {
    pageTitle.value = title;
};
</script>

<style scoped></style>
