import v2 from "@/api/v2/v2";
import BaseApi from "@/api/base-api";

class ProjectsAPI extends BaseApi {
    getProjects() {
        return v2.get("/projects");
    }
}

const projectsAPI = new ProjectsAPI();

export default projectsAPI;
